.normal-page {
    text-align: center; /* Center-align all text */
    color: black; /* Set text color to black */
}

.normal-page h1 {
    font-size: 24px; /* Adjust heading font size */
}

.normal-page p {
    font-size: 18px; /* Adjust paragraph font size */
}
