/* QueryForm.css */
.query-container {
    max-width: 375px;
    margin: 0 auto;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.benefits-section h2,
.query-section h2 {
    text-align: center;
    margin-bottom: 1rem;
}
.query-section p {
    text-align: center;
    margin-bottom: 1rem;
}

.query-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.query-form select {
    width: 105%;
    padding: 0.5rem;
    margin-bottom: 0.3rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.query-form textarea {
    width: 100%;
    height: 10%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.submit-btn,
.explore-btn {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 6px;
    color: #fff;
    background-color: #000;
    cursor: pointer;
}
.benefits-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the space between items */
    justify-content: center; /* Center items horizontally */
}

.benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content (image and text) vertically */
    text-align: center; /* Center-align the text */
    font-size: 14px; /* Adjust the text size */
}

.benefit-item img {
    border-radius: 0; /* Creates a circular shape */
    width: 50px; /* Adjust the image size */
    height: 50px; /* Ensure this matches the width for a perfect circle */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    margin-bottom: 10px; /* Space between the image and the text */
}
/* CSS */
.query-form h4 {
    margin-bottom: 5px; /* Adjust the spacing between header and input */
}

.query-form{
    margin-right: 10px;
}

.query-form input[type="text"],
.query-form input[type="tel"] {
    margin-bottom: 1px; /* Adjust the spacing between input fields */
    /*margin-right: 50px;*/
}
.benefit-item:nth-child(5) {
    margin-left: 45px;
    padding-right: 20px;
}




