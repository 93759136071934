/* EnrollmentForm.css */
.container {
  max-width: 375px; /* Typical mobile device width */
  margin: 0 auto;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for better focus */
  border-radius: 8px;
  /*overflow: hidden;*/
}

.whitespace {
  white-space: pre;
}
/*.header {*/
/*  !*display: flex;*!*/
/*  justify-content: space-between;*/
/*  margin-bottom: 1rem;*/
/*  margin-top: -1rem;*/
/*  text-align: center;*/
/*}*/

.header {
  height: 2rem;
  position: fixed;
  top: 25px;
  left: 49%;
  transform: translate(-50%, -50%);
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.header h2 {
  margin-left: 10px; /* Add some space between the image and the text */
}


.button {
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  background-color: #ffffff;
  color: #0c0c0c;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

.banner {
  text-align: center;
  margin: 3rem 1rem;
  border-radius: 50px;
}

.main {
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 8px;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
}

.list {
  list-style: none;
  padding: 0;
  gap: 1rem;
}

/*.selected {*/
/*  background-color: #535653; !* Dark green background for selected *!*/
/*  color: white; !* White text color for better contrast *!*/
/*  border: none; !* Removes border *!*/
/*  outline: 2px solid #4CAF50; !* Adds a solid green outline *!*/
/*  outline-offset: 2px; !* Optional: Adds space between the element's border and the outline *!*/
/*}*/

.selected {
  position: relative; /* Needed for absolute positioning of the pseudo-element */
  background-color: #0c0c0c; /* Dark green background for selected */
  color: #ffffff; /* White text color for better contrast */
  border: none; /* Removes border */
}


.selected::after {
  content: ''; /* Unicode character for a check mark */
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%); /* Centers the tick vertically */
  font-size: 20px; /* Adjust size as needed */
  color: white; /* Tick mark color */

}


.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
}
.dropdown {
  position: relative;
}

.dropdown select {
  width: 100%;
  height: 35px;
  padding: 8px;
  border: 1px solid #ccc;
  /*border-radius: 4px;*/
  background-color: #fff;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.dropdown select:focus {
  outline: none;
}

.dropdown::after {
  /*content: '\25BC'; !* Unicode for down arrow *!*/
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000;
}
/* Responsive styles */
@media (max-width: 768px) {
  .button-group {
    flex-direction: column;
  }

  .button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .submit-button {
    margin-top: 1rem;
  }

  .banner img {
    width: 100%;
    height: auto;
    display: block; /* This ensures there's no extra space under the image */
  }


  /* Chip styling */
  .chip {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 9999px;
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .chip:hover {
    opacity: 0.8;
  }

  .chip.selected {
    background-color: #182394; /* Tailwind's blue-500 */
    color: white;
  }

  /* Form and layout styling */
  .container {
    max-width: 640px;
    margin: 0 auto;
    padding: 1rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  /* QueryForm.css */
  .query-container {
    max-width: 375px;
    margin: 0 auto;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .benefits-section h2,
  .query-section h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .query-section p {
    text-align: center;
    margin-bottom: 1rem;
  }

  .query-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .submit-btn,
  .explore-btn {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 6px;
    color: #fff;
    background-color: #000;
    cursor: pointer;
  }
  .benefits-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust the space between items */
    justify-content: center; /* Center items horizontally */
  }

  .benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content (image and text) vertically */
    text-align: center; /* Center-align the text */
  }

  .benefit-item img {
    border-radius: 40%; /* Creates a circular shape */
    width: 100px; /* Adjust based on your preference */
    height: 100px; /* Ensure this matches the width for a perfect circle */
    object-fit: cover; /* Ensures the image covers the area without distortion */
    margin-bottom: 10px; /* Space between the image and the text */
  }
  /* CSS */
  .query-form h4 {
    margin-bottom: 5px; /* Adjust the spacing between header and input */
  }

  .query-form{
    margin-right: 10px;
  }

  .query-form input[type="text"],
  .query-form input[type="tel"] {
    margin-bottom: 1px; /* Adjust the spacing between input fields */
    /*margin-right: 50px;*/
  }


  .strikethrough {
    text-decoration: line-through;
  }

  .button-group, .list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .submit-button {
    background-color: #000000; /* Tailwind's blue-500 */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem; /* Tailwind's rounded-md */
    cursor: pointer;
    border: none;
  }

  .submit-button:hover {
    background-color: #000000; /* Tailwind's blue-600 */
  }


}