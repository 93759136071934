/* Footer styles */
footer {
    background-color: #ffffff;
    color: #fff;
    padding: 20px 0;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 395px;
    margin: 0 auto;
    padding: 1rem;
    /*overflow: hidden;*/
    background: #0c0c0c;
}

.footer-section {
    flex: 1;
    margin: 1rem 20px;
}

.footer-section h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.footer-section p {
    margin: 5px 0;
}

.footer-section a {
    color: #fff;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}


/* Machaxi Centres styles */
.machaxi-centres {
    max-height: 300px; /* Limit the height of the section */
    overflow-y: auto; /* Add vertical scroll if content exceeds max-height */
}

.machaxi-centres p {
    margin: 5px 0;
}

.bg-white{
    margin-top: 1.5rem;
    margin-left: 4.5rem;
}
