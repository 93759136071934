/* FAQ.css */
.faq-container {
    max-width: 375px;
    margin: 0 auto;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.faq-container h2 {
    text-align: center;
    margin-bottom: 1rem;
}

.faq-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.faq-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.faq-icon {
    background-color: #f0f0f0;
    border-radius: 50%;
    padding: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: red;

}

.faq-content {
    display: inline-block; /* or inline */
}



.faq-question {
    margin: 0;
    font-weight: 700;
    color: #030303;
    white-space: pre-line;
}

.faq-answer {
    margin: 0;
    font-weight: 500;
    color: #030303;
    /*white-space: pre;*/
}

.faq-footer {
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid #ddd;
    margin-top: 1rem;
}

.faq-question button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 10px;
}

